import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'pw-menu-trigger-arrow',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './menu-trigger-arrow.component.html',
  styleUrls: ['./menu-trigger-arrow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'pw-menu-trigger-arrow material-icons',
  },
})
export class MenuTriggerArrowComponent {}
