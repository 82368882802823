import { Directive, inject, signal } from '@angular/core';
import { RotateBase } from '../rotate.directive';
import { MatMenuTrigger } from '@angular/material/menu';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
  selector: '[pwRotateMenuArrow]',
  standalone: true,
})
export class RotateMenuArrowDirective extends RotateBase {
  matMenu = inject(MatMenuTrigger);
  override $rotate = signal(false);

  constructor() {
    super();
    this.init();
    this.matMenu.menuOpened
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.$rotate.set(true));
    this.matMenu.menuClosed
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.$rotate.set(false));
  }
}
